<template>
    <!--  <div class="flex relative" >-->
    <div class="relativex" :class="!isAuth ? 'dn' : 'flex'">
        <SideBarAlt />
        <Alert />
        <div class="right-dashboard1 mb6" style="margin-left: auto; ">
            <!-- search, alert, and profile -->
            <div class="mobile-line" style="background-color: #f2f6fc">
                <div class="flex justify-between w-100 center pv3" style="padding: 10px 20px">
                    <MobileMenu />

                    <div>
                        <!--            To be removed when search container below is active-->
                    </div>
                    <!--          <div class="searchContainer">-->
                    <!--            <img src="../assets/images/search.svg" alt="" />-->
                    <!--            <input class="searchBox pl2" type="text" name="search" placeholder="Search..." />-->
                    <!--          </div>-->

                    <div class="flex flex-start  items-center" >
                        <!-- <div class="pt2 pr4">
                            <a
                                href="https://simplebks.myhcpage.com/en-gb/" 
                                data-help-id="63eb803e847ed495aec7c4fe"
                                title="Help Center"
                                target="_blank"
                            ><font-awesome-icon icon="fa-solid fa-question-circle" class="fa-2x" /></a>
                        </div> -->
                        <!-- <div class="notification pr4">
                            <a href="">
                                <img src="../assets/images/notification.svg" alt="" />
                            </a>
                        </div> -->
                           <div class="customerInitials">
                            <p>{{ userData.org[0] }}</p>
                        </div>
                        <!-- eslint-disable-next-line -->
                        <div class="profile-dropdown relative" @focusout="handleShowMenu()">
                             <a class="db flex" style="gap: 8px" href="#" @click.prevent="handleShowMenu(true)">
                                <div style="font-size: 15px; font-weight: 600;cursor: pointer; margin-right: 0px">
                                    {{ userData.org }}
                                </div>
                                <img
                                    style="width: 15px; height: 20px"
                                    :src="require('@/assets/images/chevron-black.svg')"
                                    alt=""
                                />
                            </a>
                            <div
                                class="absolute"
                                style="
                                    width: 230px;
                                    background: white;
                                    box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.08);
                                    border-radius: 8px;
                                    left: auto;
                                    right: 2px;
                                "
                                v-if="state.showMenu"
                            >
                                <router-link
                                    :to="{ name: 'BusinessProfile' }"
                                    class="flex items-center pv2 ph3 overflow-hidden menu-hover"
                                >
                                    <img :src="require('@/assets/images/user-profile.svg')" style="width: 20px" alt="" />
                                    <div class="font-w1 pl3">Profile</div>
                                </router-link>
                                <router-link
                                    :to="{ name: 'AccountSettings' }"
                                    v-if="role === 'admin' || role === 'owner'"
                                    class="flex items-center pv2 ph3 overflow-hidden menu-hover"
                                >
                                    <img :src="require('@/assets/images/settings-cog.svg')" style="width: 20px" alt="" />
                                    <div class="font-w1 pl3">Settings</div>
                                </router-link>
                                <div class="box-border-top box-border-bottom">
                                    <div v-if="businesses.length">
                                        <div class="b font-w1 pv2 ph3">Switch Business</div>
                                        <router-link to="#"  v-for="business in businesses" :key="business._id"
                                            class="flex justify-start items-center pv1 ph3 overflow-hidden menu-hover" 
                                            @click="switchBusiness(business.org)"
                                        >
                                            <img :src="'https://ui-avatars.com/api/?name=' + business.name + '&size=24&rounded=true'" alt="bi" />
                                            <div class="pl3"> {{ business.name }} </div>
                                        </router-link>
                                    </div>
                                    <router-link v-if="!userData.isSubBusiness && (role === 'owner' || role === 'admin')" to="#"
                                        class="flex justify-start items-center pv1 ph3 overflow-hidden menu-hover" 
                                        @click.prevent="addBusiness()"
                                    >
                                        <font-awesome-icon 
                                            icon="fa fa-plus" 
                                            style="font-size:1.6em;" 
                                        />
                                        <span class="pl3"> Add Business </span>
                                    </router-link>
                                </div>
                                <router-link
                                    to="#"
                                    @click.prevent="signOutUser"
                                    class="flex items-center pv2 ph3 overflow-hidden menu-hover"
                                >
                                    <img :src="require('@/assets/images/logout.svg')" style="width: 20px" alt="" />
                                    <div class="font-w1 pl3">Log Out</div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mini-spacing"></div>
            <!-- search, alert, and profile -->
            <!--Body Main Content-->
            <!-- <div v-if="$route.name !== 'VerifyEmail' && userData?.emailVerifiedAt === null" class="alert alert-warning w-90 center">
                You are yet to verify your account, some features will not be available. Please check your mail. 
                <strong><a href="#" @click.prevent="resendVerifyEmailLink">Resend link</a></strong>.
            </div> -->
            <div v-if="$route.name !== 'BusinessProfile' &&
                (userData?.typeOfBusiness === null || 
                userData?.bankAccountName === null ||
                userData?.bankAccountNumber === null || 
                userData?.bankName === null)"
            >
                <div class="alert alert-warning w-90 center">
                    You are seeing this because some vital <strong>Business Details</strong> 
                    in your organization is/are missing. Click 
                    <router-link :to="{ name: 'BusinessProfile' }">here</router-link> 
                    to update.
                </div>
            </div>
            <div v-if="$route.fullPath.startsWith('/pos')" class="w-90 center">
                <div class="pos-menus">
                    <router-link active-class="active" :to="{ name: 'Pos' }">
                        <span class="pr3">Sell</span>
                    </router-link>
                    <router-link active-class="active" :to="{ name: 'PosRegister' }">
                        <span class="pr3">Open/Close Register</span>
                    </router-link>
                    <router-link active-class="active" :to="{ name: 'PosSalesHistory' }">
                        <span class="pr3">Sales History</span>
                    </router-link>
                </div>
            </div>
            <slot name="child"></slot>
        </div>
    </div>
</template>

<script>
import { onMounted, computed, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import SideBarAlt from '@/components/AppSideBarAlt'
import MobileMenu from '@/components/MobileMenu'
import Alert from '@/components/Alert'

const bgBtn = require('@/assets/images/bg-btn.png')

export default {
    name: 'AppWrapper',
    components: { SideBarAlt, MobileMenu, Alert },

    setup() {
        const store = useStore()
        const router = useRouter()

        const isAuth = store?.state?.Auth?.isAuthenticated
        const role = computed(() => store?.state?.Auth?.role)
        const userData = computed(() => store?.state?.Auth?.userData)
        const myPlan = computed(() => store?.state?.Settings?.paymentPlan)
        const businesses = computed(() => store?.state?.Settings?.businesses)

        const state = reactive({
            showMenu: false
        })

        const handleShowMenu = (payload = false) => {
            setTimeout(() => {
                state.showMenu = payload
            }, 300)
        }

        const closeWebinarBanner = () => {
            localStorage.setItem('bannerClosed', true)
            state.bannerClosed = true
        }

        const switchBusiness = orgId => {
            store.dispatch('Settings/switchBusiness', orgId)
        }

        const addBusiness = async () => {
            if (myPlan.value?.businessPlan === 'Micro Business Plan') {
                Swal.fire({
                    title: `Upgrade Plan`,
                    text: `Multi-Business feature is only available for Paid plans`,
                    showCloseButton: true,
                    confirmButtonText: 'Upgrade',
                    confirmButtonColor: '#a94442',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await router.replace({ name: 'Plan' })
                    }
                });
                return;
            }

            if (businesses.value?.length >= (myPlan.value?.businesses || 1)) {
                Swal.fire({
                    title: `Upgrade Plan`,
                    text: `You have exhausted your business limit, please upgrade.`,
                    showCloseButton: true,
                    confirmButtonText: 'Upgrade',
                    confirmButtonColor: '#a94442',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await router.replace({ name: 'Plan' })
                    }
                });
                return;
            }

            await router.replace({ name: 'AddBusiness' })
        }

        onMounted(() => {
            store.dispatch('Settings/getAccountSettings')
            store.dispatch('Settings/getPlan')
            store.dispatch('Settings/getBusinesses')
            if (localStorage.getItem('bannerClosed') !== null) {
                state.bannerClosed = Boolean(localStorage.getItem('bannerClosed'))
            }
        })

        const signOutUser = () => store.dispatch('Auth/signOut')
        const resendVerifyEmailLink = () => store.dispatch('Auth/resendVerifyEmailLink')

        return {
            role,
            isAuth,
            userData,
            bgBtn,
            signOutUser,
            state,
            handleShowMenu,
            resendVerifyEmailLink,
            closeWebinarBanner,
            myPlan,
            businesses,
            addBusiness,
            switchBusiness,
        }
    }
}
</script>

<style scoped>
.menu-hover:hover {
    background-color: rgb(233, 237, 252);
    border-radius: 8px;
    /* transform: scale(1.1); */
    transition: all 0.3s ease-in-out;
}
.customerInitials {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-items: center;
    background-color: #000000;
    margin-right: 5px;
}
.customerInitials p {
    text-align: center;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
}
.mobile-line{
    position: sticky;
    top: 68px;
    z-index: 52;
}
.pos-menus {
    display: none;
}
@media screen and (max-width: 960px) {
    .pos-menus {
        display: inline-block
    }
}
</style>
