<template>
    <TransactionImportModal :closeModal="() => toggleTransactionImportModal(false)" />
    <CreateEntity :closeModal="() => toggleCreateEntity()" />
    <UploadInventoryModal :close-modal="toggleImportInventoryModal" />
    <InventoryBulkUpdateModal :close-modal="toggleInventoryBulkUpdateModal" />
    <EntityBulkUploadModal :closeModal="() => toggleEntityBulkImportModal(false)" />
    <div class="left-dashboard1 flex" style="background: #f2f6fc">
        <div class="w-60 pt3" style="background: #f2f6fc">
            <!-- <div class="pa4" style="background-color: #e9edfc; cursor: pointer">
                <a href="https://simplebks.com" target="_blank">
                    <div class="pb3">
                        <img src="../assets/images/simple-logo-mobile.svg" alt="www.simplebks.com" />
                    </div>
                </a>

                <div class="dropdown ">
                    <router-link :to="{ name: 'AddTransaction' }"
                        style="opacity: 1; color: white; font-size: 15px; width:100%;">
                        <button class="lh-copy w-100 no-wrap" @click="changeBusinessType('sales')">Add New
                            Record</button>
                    </router-link>
                    <div class="dropdown-content">
                        <router-link v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(27)"
                            :to="{ name: 'AddTransaction' }">
                            <p @click="changeBusinessType('sales')">Inflow</p>
                        </router-link>
                        <router-link v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(26)"
                            :to="{ name: 'AddTransaction' }" @click="changeBusinessType('expenses')">
                            <p active-class="active">Outflow</p>
                        </router-link>
                        <router-link :to="{ name: 'AddTransaction' }" @click="changeBusinessType('assets_purchase')">
                            <p active-class="active">Asset acquisition</p>
                        </router-link>
                    </div>
                </div>
            </div> -->
            <div style="height: 63vh">
                <!-- <template v-if="getStarted?.showGetStarted || false"> -->
                <!-- <router-link v-if="role === 'owner' || role === 'admin'" class="flex items-center ph4 pt3 pv2x"
                    active-class="active" :to="{ name: 'GetStarted' }">
                    <img :src="require('@/assets/images/store-in.svg')" style="width: 15px" alt="" />
                    <div class="pl3">Get Started</div>
                </router-link> -->
                <!-- </template> -->
                <router-link
                    class="flex items-center ph4 pv2x pb3xx pt3xx active-hover"
                    active-class="active"
                    :to="{ name: 'Dashboard' }"
                >
                    <img :src="require('@/assets/images/Dashboard-active.svg')" style="width: 15px" alt="" />
                    <div class="pl3">Dashboard</div>
                </router-link>
                <div
                    class="flex items-center ph4 pv2 pb3xx pt3xx active-hover"
                     @mouseenter="
                        showArrowUp(1);
                        toggleDropdownOpen(1)
                    "
                    @mouseleave="
                        hideArrowUp(1);
                        toggleDropdownClose(1)
                    "
                    active-class="active"
                    v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(9)"
                >
                    <img :src="require('@/assets/images/service-svgrepo-com.svg')" style="width: 15px" alt="" />
                    <router-link :to="{ name: 'SalesAndInvoicing' }" class="pl3 pv2 w-100">Sales & Invoicing</router-link>
                    <div class="flex" @click="toggleDropdown(1)" v-if="isHovered[1] && !isDropdownOpen[1]">
                        <img :src="require('@/assets/images/arrow-down.svg')" style="width: 15px" alt="" />
                    </div>
                    <div class="flex" v-if="isDropdownOpen[1]" @click="toggleDropdown(1)">
                        <img :src="require('@/assets/images/arrow-up.svg')" style="width: 15px" alt="" />
                    </div>
                </div>
                <div v-if="isDropdownOpen[1]"
                  @mouseenter="
                        showArrowUp(1);
                        toggleDropdownOpen(1)
                    "
                    @mouseleave="
                        hideArrowUp(1);
                        toggleDropdownClose(1)
                    "
                >
                    <router-link
                        class="flex items-center ph4 pv2x pb3xx pt3xx active-hover"
                        active-class="active"
                        :to="{ name: 'AddTransactionNew' }"
                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(23)"
                    >
                        <div class="pl4 w-100">Quick Sales</div>
                    </router-link>
                    <router-link
                        class="flex items-center ph4 pv2x pb3xx pt3xx active-hover"
                        active-class="active"
                        :to="{ name: 'InvoiceSummary' }"
                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(21)"
                    >
                        <div class="pl4 w-100">Invoicing</div>
                    </router-link>
                    <router-link
                        class="flex items-center ph4 pv2x pb3xx pt3xx active-hover"
                        active-class="active"
                        :to="{ name: 'Pos' }"
                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(141)"
                    >
                        <div class="pl4 w-100">Point of Sales</div>
                        <span  v-if="myPlan?.businessPlan === 'Micro Business Plan'">
                                       <font-awesome-icon icon="fa-solid fa-lock" class="blueColor" />
                                    </span>
                    </router-link>
                    <router-link
                    v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(21)"
                        class="flex items-center ph4 pv2x pb3xx pt3xx active-hover"
                        active-class="active"
                        :to="{ name: 'Quotations' }"
                    >
                        <div class="pl4 w-100">Quotations</div>
                        <span  v-if="myPlan?.businessPlan === 'Micro Business Plan'">
                                       <font-awesome-icon icon="fa-solid fa-lock" class="blueColor" />
                                    </span>
                    </router-link>
                    <!-- <router-link
                        class="flex items-center ph4 pv2x pb3xx pt3xx active-hover"
                        active-class="active"
                        :to="{ name: 'Transactions' }"
                        @click="filterTxnBySales()"
                    >
                        <div>
                            <div class="pl4 w-100">Sales Cashbook</div>
                        </div></router-link
                    >
                    <router-link
                        class="flex items-center ph4 pv2x pb3xx pt3xx active-hover"
                        active-class="active"
                        :to="{ name: 'SalesReport' }"
                    >
                        <div class="pl4 w-100">Sales Report</div>
                    </router-link>
                    <div
                        @click="toggleTransactionImportModal(true)"
                        class="flex items-center ph4 pv2x pb3xx pt3xx active-hover divLink"
                        active-class="active"
                    >
                        <div class="pl4 w-100">Bulk Sales Upload</div>
                    </div>
                    <div class="flex items-center ph4 pv2x pb3xx pt3xx active-hover divLink">
                        <div class="pl4 w-100">Export Sales Record</div>
                    </div> -->
                </div>
                <div
                    class="flex items-center ph4 pv2 pb3xx pt3xx active-hover"
                      @mouseenter="
                        showArrowUp(2);
                        toggleDropdownOpen(2)
                    "
                    @mouseleave="
                        hideArrowUp(2);
                        toggleDropdownClose(2)
                    "
                    active-class="active"
                >
                    <img :src="require('@/assets/images/expenses.svg')" style="width: 15px" alt="" />
                    <router-link :to="{ name: 'Expenses' }" class="pl3 pv2 w-100">Expenses</router-link>
                    <div class="flex" @click="toggleDropdown(2)" v-if="isHovered[2] && !isDropdownOpen[2]">
                        <img :src="require('@/assets/images/arrow-down.svg')" style="width: 15px" alt="" />
                    </div>
                    <div class="flex" v-if="isDropdownOpen[2]" @click="toggleDropdown(2)">
                        <img :src="require('@/assets/images/arrow-up.svg')" style="width: 15px" alt="" />
                    </div>
                </div>
                <div v-if="isDropdownOpen[2]"
                  @mouseenter="
                        showArrowUp(2);
                        toggleDropdownOpen(2)
                    "
                    @mouseleave="
                        hideArrowUp(2);
                        toggleDropdownClose(2)
                    "
                >
                    <a
                        class="flex items-center ph4 pv2x pb3xx pt3xx active-hover"
                        active-class="active"
                        href="/transactions/new?type=outflows"
                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(24)"
                    >
                        <div class="pl4 w-100">Quick Expenses</div>
                    </a>
                    <div
                        @click="expensesPrepayment()"
                        class="flex items-center ph4 pv2x pb3xx pt3xx active-hover divLink"
                        active-class="active"
                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(24)"
                    >
                        <div class="pl4 w-100">Prepayments</div>
                        <span  v-if="myPlan?.businessPlan === 'Micro Business Plan'">
                            <font-awesome-icon icon="fa-solid fa-lock" class="blueColor"  />
                        </span>
                    </div>
                    <div
                        class="flex items-center ph4 pv2x pb3xx pt3xx active-hover divLink"
                        active-class="active"
                        @click="quickPurchases()"
                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(25)"
                    >
                        <div class="pl4 w-100">Quick Purchases</div>
                        <span  v-if="myPlan?.businessPlan === 'Micro Business Plan'">
                            <font-awesome-icon icon="fa-solid fa-lock" class="blueColor"  />
                        </span>
                    </div>
                    <router-link
                        class="flex items-center ph4 pv2x pb3xx pt3xx active-hover"
                        active-class="active"
                        :to="{ name: 'PurchaseOrderCreate' }"
                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(57)"
                    >
                        <div class="pl4 w-100">Purchase Order</div>
                    </router-link>
                    <div
                        class="flex items-center ph4 pv2x pb3xx pt3xx active-hover divLink"
                        active-class="active"
                        @click="filterTxnByExpenses()"
                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(4)"
                    >
                        <div class="pl4 w-100">Expense Cashbook</div>
                    </div>
                    <div
                        class="flex items-center ph4 pv2x pb3xx pt3xx active-hover divLink"
                        active-class="active"
                      
                        @click="routeTo('ExpenseReport')"
                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(17)"
                    >
                        <div class="pl4 w-100">Expense report</div>
                    </div>
                    <!-- <div
                        class="flex items-center ph4 pv2x pb3xx pt3xx active-hover divLink"
                        active-class="active"
                        @click="bulkExpenseUpload()"
                    >
                        <div class="pl4 w-100">Bulk Expense upload</div>
                    </div>
                    <div class="flex items-center ph4 pv2x pb3xx pt3xx active-hover divLink" active-class="active">
                        <div class="pl4 w-100">Export Expenses</div>
                    </div> -->
                </div>
                <div
                    class="flex items-center ph4 pv2 pb3xx pt3xx active-hover"
                      @mouseenter="
                        showArrowUp(3);
                        toggleDropdownOpen(3)
                    "
                    @mouseleave="
                        hideArrowUp(3);
                        toggleDropdownClose(3)
                    "
                    active-class="active"
                >
                    <img :src="require('@/assets/images/contact.svg')" style="width: 15px" alt="" />
                    <router-link :to="{ name: 'Contacts' }" class="pl3 pv2 w-100">Contacts</router-link>
                    <div class="flex" @click="toggleDropdown(3)" v-if="isHovered[3] && !isDropdownOpen[3]">
                        <img :src="require('@/assets/images/arrow-down.svg')" style="width: 15px" alt="" />
                    </div>
                    <div class="flex" v-if="isDropdownOpen[3]" @click="toggleDropdown(3)">
                        <img :src="require('@/assets/images/arrow-up.svg')" style="width: 15px" alt="" />
                    </div>
                </div>
                <div v-if="isDropdownOpen[3]"
                  @mouseenter="
                        showArrowUp(3);
                        toggleDropdownOpen(3)
                    "
                    @mouseleave="
                        hideArrowUp(3);
                        toggleDropdownClose(3)
                    "
                >
                    <!-- <div
                        class="flex items-center ph4 pv2x pb3xx pt3xx active-hover divLink"
                        active-class="active"
                        @click.prevent="() => toggleCreateEntity(true)"
                    >
                        <div class="pl4 w-100">Create a contact</div>
                    </div> -->
                    <router-link
                        class="flex items-center ph4 pv2x pb3xx pt3xx active-hover"
                        active-class="active"
                        :to="'#'"
                        @click="routeTo('Entities')"
                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(13)"
                    >
                        <div class="pl4 w-100">All Contacts</div>
                        <span  v-if="myPlan?.businessPlan === 'Micro Business Plan'">
                            <font-awesome-icon icon="fa-solid fa-lock" class="blueColor"  />
                        </span>
                    </router-link>
                    <a
                        class="flex items-center ph4 pv2x pb3xx pt3xx active-hover"
                        active-class="active"
                        href="/entities?group=customers"
                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(13)"
                    >
                        <div class="pl4 w-100">Customers</div>
                    </a>
                    <div
                       
                        class="flex items-center ph4 pv2x pb3xx pt3xx active-hover divLink"
                        active-class="active"
                        @click="routeTo('Entities', { group: 'suppliers' })"
                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(13)"
                    >
                        <div class="pl4 w-100">Suppliers</div>
                        <span  v-if="myPlan?.businessPlan === 'Micro Business Plan'">
                            <font-awesome-icon icon="fa-solid fa-lock" class="blueColor"  />
                        </span>
                    </div>
                    <router-link
                        class="flex items-center ph4 pv2x pb3xx pt3xx active-hover"
                        active-class="active"
                        :to="{ name: 'PnR' }"
                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(12)"
                    >
                        <div class="pl4 w-100">Debtors & Creditors</div>
                    </router-link>
                    <div
                        class="flex items-center ph4 pv2x pb3xx pt3xx active-hover divLink"
                        active-class="active"
                        
                        @click="routeTo('Entities')"
                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(13)"
                    >
                        <div class="pl4 w-100">Others</div>
                        <span  v-if="myPlan?.businessPlan === 'Micro Business Plan'">
                            <font-awesome-icon icon="fa-solid fa-lock" class="blueColor"  />
                        </span>
                    </div>
                    <!-- <div
                        class="flex items-center ph4 pv2x pb3xx pt3xx active-hover divLink"
                        active-class="active"
                        @click="bulkCreateContacts()"
                    >
                        <div class="pl4 w-100">Bulk Create Contacts</div>
                    </div>
                    <div class="flex items-center ph4 pv2x pb3xx pt3xx active-hover divLink" active-class="active">
                        <div class="pl4 w-100">Export Contacts</div>
                    </div>
                    <div class="flex items-center ph4 pv2x pb3xx pt3xx active-hover divLink" active-class="active">
                        <div class="pl4 w-100">Customer Groups</div>
                    </div> -->
                </div>
                <div
                    class="flex items-center ph4 pv2 pb3xx pt3xx active-hover"
                      @mouseenter="
                        showArrowUp(4);
                        toggleDropdownOpen(4)
                    "
                    @mouseleave="
                        hideArrowUp(4);
                        toggleDropdownClose(4)
                    "
                    active-class="active"
                >
                    <img :src="require('@/assets/images/product.svg')" style="width: 15px" alt="" />
                    <router-link :to="{ name: 'ProductsAndServices' }" class="pl3 pv2 w-100">Products & Services</router-link>
                    <div class="flex" @click="toggleDropdown(4)" v-if="isHovered[4] && !isDropdownOpen[4]">
                        <img :src="require('@/assets/images/arrow-down.svg')" style="width: 15px" alt="" />
                    </div>
                    <div class="flex" v-if="isDropdownOpen[4]" @click="toggleDropdown(4)">
                        <img :src="require('@/assets/images/arrow-up.svg')" style="width: 15px" alt="" />
                    </div>
                </div>
                <div v-if="isDropdownOpen[4]"
                  @mouseenter="
                        showArrowUp(4);
                        toggleDropdownOpen(4)
                    "
                    @mouseleave="
                        hideArrowUp(4);
                        toggleDropdownClose(4)
                    "
                >
                    <div
                    class="flex items-center ph4 pv2x pb3xx pt3xx active-hover divLink"
                        active-class="active"
                        
                        @click="routeTo('ProductsSummary')"
                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(5)"
                    >
                        <div class="pl4 w-100">Summary</div>
                        <span  v-if="myPlan?.businessPlan === 'Micro Business Plan'">
                            <font-awesome-icon icon="fa-solid fa-lock" class="blueColor"  />
                        </span>
                    </div>
                    <div
                    class="flex items-center ph4 pv2x pb3xx pt3xx active-hover divLink"
                        active-class="active"
                       
                        @click="routeTo('Products')"
                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(6)"
                    >
                        <div class="pl4 w-100">Products</div>
                        <span  v-if="myPlan?.businessPlan === 'Micro Business Plan'">
                            <font-awesome-icon icon="fa-solid fa-lock" class="blueColor"  />
                        </span>
                    </div>
                    <div
                    class="flex items-center ph4 pv2x pb3xx pt3xx active-hover divLink"
                        active-class="active"
                   
                        @click="routeTo('Services')"
                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(9)"
                    >
                        <div class="pl4 w-100">Services</div>
                        <span  v-if="myPlan?.businessPlan === 'Micro Business Plan'">
                            <font-awesome-icon icon="fa-solid fa-lock" class="blueColor"  />
                        </span>
                    </div>
                    <div
                        class="flex items-center ph4 pv2x pb3xx pt3xx active-hover  divLink"
                        active-class="active"
                        @click="routeTo('StockTransfers')"
                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(61)"
                    >
                        <div class="pl4 w-100">Transfers</div>
                        <span  v-if="myPlan?.businessPlan === 'Micro Business Plan'">
                            <font-awesome-icon icon="fa-solid fa-lock" class="blueColor"  />
                        </span>
                    </div>
                    <div
                        class="flex items-center ph4 pv2x pb3xx pt3xx active-hover divLink"
                        active-class="active"
                        :to="'#'"
                        @click="routeTo('PurchaseOrders')"
                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(56)"
                    >
                        <div class="pl4 w-100">Purchase Orders</div>
                    </div>
                    <router-link
                        class="flex items-center ph4 pv2x pb3xx pt3xx active-hover"
                        active-class="active"
                        :to="{ name: 'InventoryReport' }"
                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(18)"
                    >
                        <div class="pl4 w-100">Inventory Report</div>
                        <span  v-if="myPlan?.businessPlan === 'Micro Business Plan'">
                            <font-awesome-icon icon="fa-solid fa-lock" class="blueColor"  />
                        </span>
                    </router-link>
                    <!-- <div
                        class="flex items-center ph4 pv2x pb3xx pt3xx active-hover divLink"
                        active-class="active"
                        @click.prevent="toggleImportInventoryModal"
                    >
                        <div class="pl4 w-100">Import</div>
                    </div>
                    <div
                        class="flex items-center ph4 pv2x pb3xx pt3xx active-hover divLink"
                        active-class="active"
                        @click.prevent="downloadInventoryTemplate"
                    >
                        <div class="pl4 w-100">Export</div>
                    </div>
                    <div
                        class="flex items-center ph4 pv2x pb3xx pt3xx active-hover divLink"
                        active-class="active"
                        @click="toggleInventoryBulkUpdateModal"
                    >
                        <div class="pl4 w-100">Bulk Update</div>
                    </div> -->
                    <router-link
                        class="flex items-center ph4 pv2x pb3xx pt3xx active-hover"
                        active-class="active"
                        :to="{ name: 'ProductCategories' }"
                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(68)"
                    >
                        <div class="pl4 w-100">Categories</div>
                    </router-link>
                    <router-link
                        class="flex items-center ph4 pv2x pb3xx pt3xx active-hover"
                        active-class="active"
                        :to="{ name: 'ProductBrands' }"
                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(72)"
                    >
                        <div class="pl4 w-100">Brands</div>
                    </router-link>
                </div>
                <!-- <router-link v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(51)"
                    class="flex items-center ph4 pv2x" :class="{ active: $route.fullPath.startsWith('/transactions') }"
                    active-class="active" :to="{ name: 'Transactions' }">
                    <img :src="require('@/assets/images/Transactions.svg')" style="width: 15px" alt="" />
                    <div class="pl3">Transactions</div>
                </router-link> -->

                <!-- <router-link v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(50)"
                    class="flex items-center ph4 pv2x" :class="{ active: $route.fullPath.startsWith('/inventories') }"
                    active-class="active" :to="{ name: 'ProductsSummary' }">
                    <img :src="require('@/assets/images/inventory.svg')" style="width: 15px" alt="" />
                    <div class="pl3">Inventory</div>
                </router-link> -->
                <!-- <router-link v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(9)"
                    class="flex items-center ph4 pv2x" :class="{ active: $route.fullPath.startsWith('/services') }"
                    active-class="active" :to="{ name: 'Services' }">
                    <img :src="require('@/assets/images/service-svgrepo-com.svg')" style="width: 20px" alt="" />
                    <div class="pl3">Services</div>
                </router-link> -->

                <!-- <router-link v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(13)"
                    class="flex items-center ph4 pv2x" :class="{ active: $route.fullPath.startsWith('/entities') }"
                    active-class="active" :to="{ name: 'Entities' }">
                    <img :src="require('@/assets/images/entity.svg')" style="width: 15px" alt="" />
                    <div class="pl3">Customers</div>
                </router-link> -->

                <!-- <router-link v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(21)"
                    class="flex items-center ph4 pv2x" :class="{ active: $route.fullPath.startsWith('/invoice') }"
                    active-class="active" :to="{ name: 'InvoiceSummary' }">
                    <img :src="require('@/assets/images/invoice-svgrepo-com.svg')" style="width: 15px" alt="" />
                    <div class="pl3">Invoicing</div>
                </router-link>
                <router-link v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(12)"
                    class="flex items-center ph4 pv2x" active-class="active" :to="{ name: 'PnR' }">
                    <img :src="require('@/assets/images/payables.svg')" style="width: 15px" alt="" />
                    <div class="pl3">Debtors & Creditors</div>
                </router-link> -->

                <div
                    v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(21)"
                    class="flex items-center ph4 pv2 active-hover"
                    :class="{
                        active: $route.fullPath.startsWith('/accounting') || $route.fullPath.startsWith('/chart-accounts'),
                    }"
                    active-class="active"
                    @mouseenter="
                        showArrowUp(5);
                        toggleDropdownOpen(5)
                    "
                    @mouseleave="
                        hideArrowUp(5);
                        toggleDropdownClose(5)
                    "
                >
                    <img :src="require('@/assets/images/accounting.svg')" style="width: 15px" alt="" />
                    <router-link :to="{ name: 'Accounting' }" class="pl3 pv2 w-100">Accounting</router-link>
                    <div class="flex" @click="toggleDropdown(5)" v-if="isHovered[5] && !isDropdownOpen[5]">
                        <img :src="require('@/assets/images/arrow-down.svg')" style="width: 15px" alt="" />
                    </div>
                    <div class="flex" v-if="isDropdownOpen[5]" @click="toggleDropdown(5)">
                        <img :src="require('@/assets/images/arrow-up.svg')" style="width: 15px" alt="" />
                    </div>
                </div>
                <div v-if="isDropdownOpen[5]"
                  @mouseenter="
                        showArrowUp(5);
                        toggleDropdownOpen(5)
                    "
                    @mouseleave="
                        hideArrowUp(5);
                        toggleDropdownClose(5)
                    "
                >
                    <!-- <router-link
                        class="flex items-center ph4 pv2x pb3xx pt3xx active-hover"
                        active-class="active"
                        :to="{ name: 'Transactions' }"
                    >
                        <div class="pl4 w-100">Transactions</div>
                    </router-link> -->
                    <div
                        class="flex items-center ph4 pv2x pb3xx pt3xx active-hover divLink"
                        active-class="active"
                        @click="routeTo('ProfitAndLoss')"
                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(19)"
                    >
                        <div class="pl4 w-100">Profit & Loss Account</div>
                    </div>
                    <div
                        class="flex items-center ph4 pv2x pb3xx pt3xx active-hover divLink"
                        active-class="active"
                        
                        @click="routeTo('BalanceSheet')"
                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(20)"
                    >
                        <div class="pl4 w-100">Balance Sheet</div>
                        <span  v-if="myPlan?.businessPlan === 'Micro Business Plan'">
                            <font-awesome-icon icon="fa-solid fa-lock" class="blueColor"  />
                        </span>
                    </div>
                    <router-link
                        class="flex items-center ph4 pv2x pb3xx pt3xx active-hover"
                        active-class="active"
                        :to="{ name: 'ManageAssets' }"
                    >
                        <div class="pl4 w-100">Chart of Accounts</div>
                        <span  v-if="myPlan?.businessPlan === 'Micro Business Plan'">
                            <font-awesome-icon icon="fa-solid fa-lock" class="blueColor"  />
                        </span>
                    </router-link>
                    <!-- <div class="flex items-center ph4 pv2x pb3xx pt3xx active-hover divLink" active-class="active">
                        <div class="pl4 w-100">Chart of Accounts</div>
                    </div>
                    <div class="flex items-center ph4 pv2x pb3xx pt3xx active-hover divLink" active-class="active">
                        <div class="pl4 w-100">Journal Entry</div>
                    </div>
                    <div class="flex items-center ph4 pv2x pb3xx pt3xx active-hover divLink" active-class="active">
                        <div class="pl4 w-100">Bank Transfers</div>
                    </div>
                    <div class="flex items-center ph4 pv2x pb3xx pt3xx active-hover divLink" active-class="active">
                        <div class="pl4 w-100">Bank Reconciliation</div>
                    </div>
                    <div class="flex items-center ph4 pv2x pb3xx pt3xx active-hover divLink" active-class="active">
                        <div class="pl4 w-100">Trial Balance</div>
                    </div>

                    <div class="flex items-center ph4 pv2x pb3xx pt3xx active-hover divLink" active-class="active">
                        <div class="pl4 w-100">Cashflow Statement</div>
                    </div> -->
                </div>
                <router-link
                    v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(51)"
                    class="flex items-center ph4 pv2x"
                    :class="{ active: $route.fullPath.startsWith('/transactions') }"
                    active-class="active"
                    :to="{ name: 'Transactions' }"
                >
                    <img src="../assets/images/Transactions.svg" style="width: 15px" alt="" />
                    <div class="pl3">Transactions</div>
                </router-link>
                <router-link
                    v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(141)"
                    class="flex items-center ph4 pv2x"
                    :class="{ active: $route.fullPath.startsWith('/pos') }"
                    active-class="active"
                    :to="{ name: 'Pos' }"
                >
                    <img src="../assets/images/point.svg" style="width: 15px" alt="" />
                    <div class="pl3">Point of Sale</div>
                </router-link>
                <router-link
                    v-if="role === 'owner' || role === 'admin'"
                    class="flex items-center ph4 pv2x"
                    :class="{ active: $route.fullPath.startsWith('/asset-management') }"
                    active-class="active"
                    :to="{ name: 'AssetManagement' }"
                >
                    <img src="../assets/images/point.svg" style="width: 15px" alt="" />
                    <div class="pl3">Asset Management</div>
                </router-link>
                <router-link
                    v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(15)"
                    class="flex items-center ph4 pv2x active-hover"
                    :class="{ active: $route.fullPath.startsWith('/reports') }"
                    active-class="active"
                    :to="{ name: 'Reports' }"
                >
                    <img :src="require('@/assets/images/report.svg')" style="width: 15px" alt="" />
                    <div class="pl3">Report</div>
                </router-link>
                <!--      <router-link class="flex items-center ph4 pv2x" active-class="active" to="#">-->
                <!--        <img src="../assets/images/insight.svg" style="width: 15px" alt="" />-->
                <!--        <div class="pl3">Insights</div>-->
                <!--      </router-link>-->
                <router-link
                    v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(142)"
                    class="flex items-center ph4 pv2x active-hover"
                    :class="{ active: $route.fullPath.startsWith('/production') }"
                    active-class="active"
                    :to="{ name: 'ProductionNew' }"
                >
                    <img src="../assets/images/production.svg" style="width: 15px" alt="" />
                    <div class="pl3">Production</div>
                </router-link>
                <router-link
                    class="flex items-center ph4 pv2x active-hover"
                    :class="{ active: $route.fullPath.startsWith('/settings') }"
                    active-class="active"
                    :to="{ name: 'SettingNew' }"
                >
                    <img :src="require('@/assets/images/settings.svg')" style="width: 15px" alt="" />
                    <div class="pl3">Settings</div>
                </router-link>
            </div>
        </div>

        <div class="pa3 w-40" style="background: #f6f8fe">
            <router-link active-class="active" :to="{ name: 'Pos' }">
                <div class="pv3">Sell</div>
            </router-link>
            <router-link active-class="active" :to="{ name: 'PosRegister' }">
                <div class="pv3">Open/Close Register</div>
            </router-link>
            <router-link active-class="active" :to="{ name: 'PosSalesHistory' }">
                <div class="pv3">Sales History</div>
            </router-link>
            <!-- <a href="#">
                <div class="pv3">Status</div>
            </a>
            <a href="#">
                <div class="pv3">Settings</div>
            </a> -->
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line
import { computed, onMounted, reactive, watch, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import bgBtn from '@/assets/images/bg-btn.png'
import TransactionImportModal from '@/views/Transactions/widgets/TransactionImportModal'
import UploadInventoryModal from '@/components/UploadInventoryModal'
import InventoryBulkUpdateModal from '@/views/Inventory/widgets/InventoryBulkUpdateModal'
import EntityBulkUploadModal from '@/views/Entities/widgets/EntityBulkUploadModal'
import CreateProduct from '@/components/CreateProduct.vue'
import CreateEntity from '@/components/CreateEntity'
export default {
    name: 'SideBarAlt',
    components: {
        TransactionImportModal,
        CreateEntity,
        EntityBulkUploadModal,
        CreateProduct,
        UploadInventoryModal,
        InventoryBulkUpdateModal,
    },
    setup(props, { emit }) {
        const store = useStore()
        const route = useRoute()
        const router = useRouter()
        const userData = computed(() => store?.state?.Auth?.userData)
        const orgInitials = computed(() => store?.state?.Auth?.orgInitials)
        const orgImage = computed(() => store?.state?.Auth?.orgImage)
        const getStarted = computed(() => store?.state?.Settings?.getStarted)
        const role = computed(() => store?.state?.Auth?.role)
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
        const myPlan = computed(() => store?.state?.Settings?.paymentPlan)
        const orgData = computed(() => store?.state?.Auth?.userData)

        const isDropdownOpen = ref({
            1: false,
            2: false,
            3: false,
            4: false,
            5: false,
        })
        const isHovered = ref({
            1: false,
            2: false,
            3: false,
            4: false,
            5: false,
        })

        const initialState = {
            canAccessPos: false,
        }
        const state = reactive(initialState)

        const triggerGetStarted = () => {
            onMounted(() => store.dispatch('Settings/getGetStarted'))
        }

        const changeBusinessType = (type) => {
            // console.log(type, 'type')
            store.dispatch('DashboardStats/getBusinessType', type)
        }

        const toggleDropdown = (dropdownIndex) => {
            isDropdownOpen.value[dropdownIndex] = !isDropdownOpen.value[dropdownIndex]
        }

          const toggleDropdownOpen = (dropdownIndex) => {
            isDropdownOpen.value[dropdownIndex] = true
        }

           const toggleDropdownClose = (dropdownIndex) => {
            isDropdownOpen.value[dropdownIndex] = false
        }

        const showArrowUp = (dropdownNumber) => {
            isHovered.value[dropdownNumber] = true
        }

        const hideArrowUp = (dropdownNumber) => {
            isHovered.value[dropdownNumber] = false
        }
        const filterTxnBySales = () => {
            router.push({ path: '/transactions', query: { limit: 25, type: 'sales', name: 'Sales-Journal' } })
        }
        const toggleTransactionImportModal = (status) => {
            store.dispatch('Transaction/toggleTransactionImportModal', status)
        }
        const quickPurchases = () => {
            const isFreePlan = checkFreePlan()
            if (isFreePlan) {
                return
            }
            changeBusinessType('purchases')
            router.push({ path: '/transactions/new', query: { type: 'outflows', category: 'purchases' } })
            changeBusinessType('purchases')
        }
        const filterTxnByExpenses = () => {
            router.push({ path: '/transactions', query: { limit: 25, type: 'expenses', name: 'Expense-Journal' } })
        }
        const bulkExpenseUpload = () => {
            toggleTransactionImportModal(true)
        }
        const toggleImportInventoryModal = () => {
            store.dispatch('Inventory/toggleImportInventoryModal')
        }
        const downloadInventoryTemplate = () => {
            store.dispatch('Inventory/downloadInventoryTemplate').then((response) => {
                if (response?.data?.error) {
                    console.error(response.data.error)
                }

                const fileURL = window.URL.createObjectURL(new Blob([response]))
                const fileLink = document.createElement('a')
                fileLink.href = fileURL
                const fileName = `${orgData.value?.org}-inventory-template.csv`
                fileLink.setAttribute('download', fileName)
                fileLink.setAttribute('target', '_blank')
                document.body.appendChild(fileLink)
                fileLink.click()
                fileLink.remove()
                // state.downloading = false
            })
        }

        const checkFreePlan = () => {
            if (myPlan.value.businessPlan === 'Micro Business Plan') {
                Swal.fire({
                    title: `Upgrade Plan`,
                    text: `This feature is not available on free plan. Upgrade to unlock!`,
                    showCloseButton: true,
                    confirmButtonText: 'Upgrade',
                    confirmButtonColor: '#a94442',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await router.replace({ name: 'Plan' })
                    }
                })
                return true
            }

            return false
        }

        const expensesPrepayment = () => {
            const isFreePlan = checkFreePlan()
            if (isFreePlan) {
                return
            }
            router.push({ path: '/transactions/new', query: { type: 'outflows', prepayment: true } })
            changeBusinessType('expenses')
        }
        const toggleInventoryBulkUpdateModal = () => {
            store.dispatch('Inventory/toggleInventoryBulkUpdateModal')
        }

        const routeTo = (path,  query = {}) => {
            const isFreePlan = checkFreePlan()
            if (isFreePlan) {
                return
            }
            router.push({ name: path, query })
        }

        watch(
            () => role.value,
            // eslint-disable-next-line
            (curr, prev) => {
                if (curr === 'owner' || curr === 'admin') {
                    triggerGetStarted()
                }
            }
        )

        watch(
            () => myPlan.value,
            (curr, prev) => {
                const accessPlans = ['Medium Enterprise Plan', 'Small Business Plan', 'Store Plan', 'Enterprise Plan']
                if (accessPlans.includes(curr?.businessPlan)) {
                    state.canAccessPos = true
                }

                store.dispatch('Pos/setPosAccess', state.canAccessPos)
            }
        )

        onMounted(() => {
            store.dispatch('Settings/getPlan')
        })

        return {
            state,
            bgBtn,
            userData,
            orgInitials,
            role,
            rolePermissions,
            getStarted,
            orgImage,
            changeBusinessType,
            myPlan,
            isDropdownOpen,
            isHovered,
            toggleDropdown,
            showArrowUp,
            hideArrowUp,
            filterTxnBySales,
            toggleTransactionImportModal,
            quickPurchases,
            filterTxnByExpenses,
            bulkExpenseUpload,
            toggleImportInventoryModal,
            downloadInventoryTemplate,
            toggleInventoryBulkUpdateModal,
            toggleDropdownOpen,
            toggleDropdownClose,
            expensesPrepayment,
            routeTo,
        }
    },
}
</script>

<style scoped>
.pv2 {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
}

.pv2x {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
}

.overflow {
    overflow-x: hidden !important;
    overflow-y: auto !important;
}

.footer {
    /* bottom: 8px; */
    /* width: 90%; */
    /* position: absolute; */
    /* margin-left: 20px; */
    /* margin-right: 20px; */
    position: fixed;
    bottom: 0;
    width: 286px;
}

.no-wrap {
    white-space: nowrap;
}

.dropdown {
    position: relative;
    display: flex;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    /* min-width: 80%; */
    width: 100%;
    /* left: 14rem; */
    top: 3rem;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.432);
    z-index: 99999999999999999999 !important;
    color: #58595f;
    border-radius: 4px;
}

.dropdown:hover .dropdown-content {
    display: block;
    z-index: 99999999999999999999 !important;
}

.dropdown-content p {
    list-style-type: none !important;
    /* margin-bottom: 20px; */
    font-size: 14px;
    /* font-weight: bold; */
    /* padding: 16px; */
    padding: 8px 16px;
    z-index: 9999999999999999999999 !important;
    overflow: hidden;
}

.dropdown-content p:hover {
    background-color: #132c8c !important;
    padding: 0px;
    color: #ffffff;
    /* padding: 16px; */
    padding: 8px 16px;
    z-index: 999999999999999999999 !important;
}
.active-hover:hover {
    border: 1.5px solid #d0d9ff;
}
.divLink {
    color: #050a28;
    opacity: 0.75;
    cursor: pointer;
}
</style>
